const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    selectedLanguage : "",
    error: false,
    process: "",
    showModal: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_LANGUAGES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_LANGUAGES":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_LANGUAGES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_LANGUAGE_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show
            }
        case "SET_VALUE_LANGUAGE":
            let detail = state.detail;
            if (action.payload.index === null && action.payload.kItem === null) {
                detail[action.payload.key][action.payload.subKey] = action.payload.value;
            }
            if (action.payload.index !== null && action.payload.kItem === null) {
                detail[action.payload.key][action.payload.subKey][action.payload.index] = action.payload.value;
            }
            if (action.payload.index !== null && action.payload.kItem !== null) {
                detail[action.payload.key][action.payload.subKey][action.payload.index][action.payload.kItem] = action.payload.value;
            }
            return {
                ...state,
                detail: detail
            }
        case "SET_VALUE_ERROR_LANGUAGE":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_LANGUAGE":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_LANGUAGE":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                selectedLanguage : action.selected
            }
        case "ERROR_DETAIL_LANGUAGE":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_LANGUAGE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_LANGUAGE":
            return {
                ...state,
                isFetchingActions: false,
                showModal: false
            }
        case "ERROR_UPDATE_LANGUAGE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
