import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    title: "",
    title_eng: "",
    text: "",
    text_eng: "",
    description: "",
    description_eng: "",
    category: "",
    category_eng: "",
    helperText: "",
    helperText_eng: "",
    image: [],
    text_eng_helpertext: "",
    text_helpertext: "",
    image_helpertext: "",
    image_error: "",
    title_helpertext: "",
    title_eng_helpertext: "",
    description_helpertext: "",
    description_eng_helpertext: "",
    category_helpertext: "",
    category_eng_helpertext: "",
    helperText_helpertext: "",
    helperText_eng_helpertext: "",
    text_error: false,
    text_eng_error: false,
    title_error: false,
    title_eng_error: false,
    description_error: false,
    description_eng_error: false,
    category_error: false,
    category_eng_error: false,
    helperText_error: false,
    helperText_eng_error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_CASES":
            return {
                ...state,
                isFetching: true,
                showModal: false,
                isFetchingActions: false,
                isFetchingDetail: false,
                error: false
            }
        case "FETCH_ALL_CASES":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_CASES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_CASE_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                title: action.payload.process !== "create" ? state.title : "",
                description: action.payload.process !== "create" ? state.description : "",
                title_eng: action.payload.process !== "create" ? state.title_eng : "",
                text: action.payload.process !== "create" ? state.text : "",
                text_eng: action.payload.process !== "create" ? state.text_eng : "",
                description_eng: action.payload.process !== "create" ? state.description_eng : "",
                category: action.payload.process !== "create" ? state.category : "",
                category_eng: action.payload.process !== "create" ? state.category_eng : "",
                helperText: action.payload.process !== "create" ? state.helperText : "",
                helperText_eng: action.payload.process !== "create" ? state.helperText_eng : "",
                title_helpertext: "",
                title_eng_helpertext: "",
                description_helpertext: "",
                description_eng_helpertext: "",
                category_helpertext: "",
                category_eng_helpertext: "",
                helperText_helpertext: "",
                helperText_eng_helpertext: "",
                title_error: false,
                description_error: false,
                title_eng_error: false,
                description_eng_error: false,
                category_error: false,
                category_eng_error: false,
                helperText_error: false,
                helperText_eng_error: false,
                image: action.payload.process === "create" ? [] : state.image
            }
        case "SET_VALUE_CASE":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CASE":
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_INSERT_CASES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_CASES":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data,
                title: "",
                description: ""
            }
        case "ERROR_INSERT_CASES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_DETAIL_CASE":
            return {
                ...state,
                isFetchingDetail: true,
                isFetchingActions: false,
                error: false
            }
        case "FETCH_DETAIL_CASE":
            let imgs = [];
            action.payload.data.image.map((item, i) => {
                return imgs.push(globalURL + "/images/" + item.name);
            });
            return {
                ...state,
                isFetchingDetail: false,
                isFetchingActions: false,
                detail: action.payload.data,
                title: action.payload.data.title.tr,
                text: action.payload.data.text.tr,
                text_eng: action.payload.data.text.eng,
                title_eng: action.payload.data.title.eng,
                description: action.payload.data.description.tr,
                description_eng: action.payload.data.description.eng,
                category_eng: action.payload.data.category.eng,
                helperText_eng: action.payload.data.helperText.eng,
                category: action.payload.data.category.tr,
                helperText: action.payload.data.helperText.tr,
                image: imgs
            }
        case "ERROR_DETAIL_CASE":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_CASE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_CASE":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                title: "",
                title_eng: "",
                description: "",
                description_eng: "",
                text: "",
                text_eng: "",
                category: "",
                category_eng: "",
                helperText: "",
                helperText_eng: "",
                text_helpertext: "",
                text_eng_helpertext: "",
                title_helpertext: "",
                title_eng_helpertext: "",
                description_helpertext: "",
                description_eng_helpertext: "",
                category_helpertext: "",
                category_eng_helpertext: "",
                helperText_helpertext: "",
                helperText_eng_helpertext: "",
                text_eng_error: false,
                text_error: false,
                title_error: false,
                title_eng_error: false,
                description_error: false,
                description_eng_error: false,
                category_error: false,
                category_eng_error: false,
                helperText_error: false,
                helperText_eng_error: false,
            }
        case "ERROR_UPDATE_CASE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_CASE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_CASE":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_CASE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
