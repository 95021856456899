import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,

} from "../../../../../redux/actions/feedbackActions";
import moment from '../../../../constants/moment';
import {
    Button,
    Modal,
    Badge,
} from "react-bootstrap";
import DataTable from "../../../../helpers/datatable/DataTable";
import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';
import { returnStatusColor } from "../../../../helpers/functions";
import {
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    FormControl,
} from "@material-ui/core";

function FeedbacksPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const columns = [
        {
            dataField: "name",
            text: "Ad",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "surname",
            text: "Soyad",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "phone",
            text: "Tel",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "subject",
            text: "Talep Konusu",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses,
            formatter: (cell) => {
                var subject = "";
                switch (cell) {
                    case 1:
                        subject = "Demo Talebi";
                        break;
                    case 2:
                        subject = "Bilgi Edinme";
                        break;
                    case 3:
                        subject = "Görüş Bildirim";
                        break;
                    default:
                        break;
                }
                return subject;
            },
        },
        {
            dataField: "feedBackState",
            text: "Talep Durumu",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses,
            formatter: (cell) => {
                return <Badge variant={returnStatusColor(cell)} className="mr-1">
                    {cell}
                </Badge>
            },
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.feedback.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.feedback.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.feedback.process === "create" ? "fa fa-plus" : props.feedback.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.feedback.process === "create" ? "Yeni Talep" : props.feedback.process === "update" ? "Talep Detayı" : "Talep Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.feedback.process === "create" || props.feedback.process === "update" ?
                    <div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>AD</TableCell>
                                    <TableCell>SOYAD</TableCell>
                                    <TableCell>EMAIL</TableCell>
                                    <TableCell>TEL</TableCell>
                                    <TableCell>KONU</TableCell>
                                    <TableCell>TALEP ZAMANI</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {props.feedback.detail.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.feedback.detail.surname}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.feedback.detail.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.feedback.detail.phone}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {
                                            props.feedback.detail.subject === 1 ?
                                                "Demo Talebi" : props.feedback.detail.subject === 2 ?
                                                    "Bilgi Edinme" : props.feedback.detail.subject === 3 ?
                                                        "Görüş Bildirim" : ""
                                        }

                                    </TableCell>
                                    <TableCell>
                                        {moment(props.feedback.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <FormControl style={{ marginLeft: 6, marginTop: 20 }} className="form-control" variant="outlined">

                            <InputLabel htmlFor="outlined-age-simple">
                                Talep Durumu
                            </InputLabel>
                            <Select
                                fullWidth
                                value={props.feedback.detail.feedBackState}
                                onChange={(event) => props.setValue("feedBackState", event.target.value)}
                                input={
                                    <OutlinedInput
                                        labelWidth={100}
                                        name="age"
                                    />
                                }
                            >
                                <MenuItem value={"pending"}>Aksiyon Bekliyor</MenuItem>
                                <MenuItem value={"solved"}>Talep Çözüldü</MenuItem>
                            </Select>

                        </FormControl>

                        {
                            props.feedback.process === "update" && props.feedback.detail !== null && props.feedback.detail.updatedBy ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>TALEP DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {
                                                props.feedback.detail.updatedBy !== undefined && props.feedback.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.feedback.detail.updatedBy.name[0]).toUpperCase() + String(props.feedback.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.feedback.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.feedback.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.feedback.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }
                    </div> :
                    props.table.row !== null && props.table.row.name ?
                        <>
                            <b>{'"'}{props.table.row.name}{' '}{props.table.row.surname}{'"'}</b> kullanıcısının talebini silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.feedback.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.feedback.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.feedback.isFetchingActions} variant="primary" onClick={() => {
                    if (props.feedback.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            props.feedback.detail
                            , props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }}>
                    {
                        props.feedback.isFetchingActions ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.feedback.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            token={props.auth.user.token}
            actionsButtonHidden={true}
            handleModal={props.toggleModal}
            refreshTable={props.getAll}
            title="Müşteri Talepleri"
            buttonTitle="Yeni Sipariş"
            cardIcon="fa fa-comments"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.feedback.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        feedback: state.feedback,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        update,
        deleteEntity,
        tableActions,

    })(FeedbacksPage);
