/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
// import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  // const { authUser } = useSelector(
  //   ({ auth }) => ({
  //     authUser: auth.user
  //   }),
  //   shallowEqual
  // );
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>



        <li className="menu-section ">
          <h4 className="menu-text">YÖNETİM</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/operations",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/operations">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">SİTE YÖNETİM</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/user"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/user">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Kullanıcı İşlemleri</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/roles"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/roles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Roller</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/titles"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/titles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Görevler</span>
                  </NavLink>
                </li>



                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/banner"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/banner">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Bannerlar</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/language"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/language">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Dil Ayarları</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/customerComments"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/customerComments">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Müşteri Yorumları</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/references"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/references">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Referanslar</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/operations/cases"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/operations/cases">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Çalışmalar</span>
                  </NavLink>
                </li>



                {/* <li
                        className={`menu-item ${getMenuItemActive(
                          "/operations/language"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/operations/language">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Dil İşlemleri</span>
                        </NavLink>
                      </li> */}
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>


        <li className="menu-section ">
          <h4 className="menu-text">OPERASYON</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/product",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/product">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Clothes/Dress.svg")}
              />
            </span>
            <span className="menu-text">ÜRÜN</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">ÜRÜN</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/product/all-products")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/product/all-products">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Tüm Ürünler</span>
                </NavLink>
              </li>


              <li
                className={`menu-item ${getMenuItemActive("/product/size")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/product/size">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Boyut / Kg</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/product/category")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/product/category">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Kategori</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/product/color")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/product/color">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Renk</span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/stock",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/stock">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
              />
            </span>
            <span className="menu-text">DEPO</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">DEPO</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/stock/stock-dashboard")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/stock/stock-dashboard">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Stok Yönetimi</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item ${getMenuItemActive("/stock/search-stock")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/stock/search-stock">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Stok Ara</span>
                </NavLink>
              </li> */}

            </ul>
          </div>
        </li>


        <li className="menu-section ">
          <h4 className="menu-text">PAZARLAMA</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>


        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/crm",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/crm">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Smile.svg")}
              />
            </span>
            <span className="menu-text">CRM</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">CRM</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/crm/customers")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/crm/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Müşteri Listesi</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* <li
                className={`menu-item ${getMenuItemActive("/crm/find-customers")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/crm/find-customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Müşteri Ara</span>
                </NavLink>
              </li> */}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/crm/messages")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/crm/messages">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Müşteri Talepleri</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/crm/blacklist")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/crm/blacklist">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Kara Liste</span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/mailing",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/mailing">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
              />
            </span>
            <span className="menu-text">MAILING</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">MAILING</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/mailing/bulletin")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/mailing/bulletin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Bülten / Mail Listesi</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/mailing/quitlist")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/mailing/quitlist">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Listeden Çıkanlar</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/mailing/spamlist")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/mailing/spamlist">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Spam Listesi</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/mailing/blacklist")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/mailing/blacklist">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Kara Liste</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/mailing/templates")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/mailing/templates">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Şablonlar</span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li>

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/notification",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/notification">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Devices/Phone.svg")}
              />
            </span>
            <span className="menu-text">BİLDİRİMLER</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">BİLDİRİMLER</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/notification/bulletin")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/notification/bulletin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Bildirim Listesi</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/notification/quitlist")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/notification/quitlist">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Bildirim İzni Kaldıranlar</span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li> */}

      </ul>
    </>
  );
}
