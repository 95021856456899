import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    toggleModal,
    getAll,
    setValue,
    update,
    tableActions,

} from "../../../../../redux/actions/languageActions";
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Modal,
    Row,
    Col,
    Tab,
    Nav,
} from "react-bootstrap";
import {
    TextField,
    Typography,
    Switch,
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


function LanguagePage(props) {
    const classes = useStyles();
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "id",
            text: "Dil Kodu",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token, true)
            }

        }
    ];

    return <>
        <Loading show={props.language.isFetchingDetail} />
        <Modal backdrop="static" size="xl" show={props.language.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.language.process === "create" ? "fa fa-plus" : props.language.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.language.process === "create" ? "Yeni Dil" : props.language.process === "update" ? "Dil Düzenle" : "Dil Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.language.detail !== null ? <Tab.Container id="left-tabs-example" defaultActiveKey="general">
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column">
                                    {
                                        Object.keys(props.language.detail).filter((hItem) => { return String(hItem).includes("_") === false }).sort().map((headerKey, hIndex) => {
                                            return <Nav.Item>
                                                <Nav.Link style={{ fontWeight: "bolder" }} eventKey={hIndex === 0 ? "general" : headerKey}>{String(headerKey).toUpperCase()}</Nav.Link>
                                            </Nav.Item>
                                        })
                                    }

                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Tab.Content>
                                    {
                                        Object.keys(props.language.detail).filter((bItem) => { return String(bItem).includes("_") === false }).sort().map((key, bIndex) => {
                                            return <Tab.Pane eventKey={bIndex === 0 ? "general" : key}>
                                                {
                                                    Object.keys(props.language.detail[key]).filter((xSItem) => { return String(xSItem).includes("_") === false }).sort().map((sKey) => {
                                                        if (typeof (props.language.detail[key][sKey]) === "string") {
                                                            return <div key={sKey} className='row'>
                                                                <div className='col-md-12'>
                                                                    <TextField
                                                                        label={String(sKey).toLowerCase()}
                                                                        value={props.language.detail[key][sKey]}
                                                                        onChange={(event) => props.setValue(key, event.target.value, sKey, null, null)}
                                                                        autoComplete="off"
                                                                        fullWidth
                                                                        margin="normal"
                                                                        variant="standard"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            style: {
                                                                                marginLeft: 0
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>


                                                        } else if (typeof (props.language.detail[key][sKey]) === "object") {

                                                            if (props.language.detail[key][sKey][0]) {
                                                                // array
                                                                return <>
                                                                    <br />
                                                                    <Typography style={{ fontWeight: "bolder" }} className={classes.heading}>{String(sKey).toLocaleLowerCase("tr")}</Typography>
                                                                    {
                                                                        props.language.detail[key][sKey].map((item, index) => {
                                                                            if (typeof item === "object") {
                                                                                return <div className='row'>
                                                                                    {
                                                                                        Object.keys(item).filter((xSItem) => { return String(xSItem).includes("_") === false }).map((kItem, iItem) => {
                                                                                            return <div key={String(kItem).toLowerCase() + " [" + index + "]"} className={'col-md-' + String(Number(12 / Object.keys(item).length))}>
                                                                                               {
                                                                                                typeof props.language.detail[key][sKey][index][kItem] === "boolean" ?
                                                                                                <div className='row' style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                                                                <Typography style={{ marginTop: 10}} className={classes.heading}>{String(kItem).toLowerCase() + " [" + index + "]"}</Typography>
                                                                                                <Switch
                                                                                                    color="primary"
                                                                                                    checked={props.language.detail[key][sKey][index][kItem]}
                                                                                                    onChange={(event) => props.setValue(key, event.target.checked, sKey, index, kItem)}
                                                                                                    value={props.language.detail[key][sKey][index][kItem]}
                                                                                                    title={String(kItem).toLowerCase() + " [" + index + "]"}
                                                                                                />
                                                                                                </div>
                                                                                                 :
                                                                                                 <TextField
                                                                                                 label={String(kItem).toLowerCase() + " [" + index + "]"}
                                                                                                 value={props.language.detail[key][sKey][index][kItem]}
                                                                                                 onChange={(event) => props.setValue(key, event.target.value, sKey, index, kItem)}
                                                                                                 autoComplete="off"
                                                                                                 fullWidth
                                                                                                 margin="normal"
                                                                                                 variant="standard"
                                                                                                 InputLabelProps={{
                                                                                                     shrink: true,
                                                                                                 }}
                                                                                                 FormHelperTextProps={{
                                                                                                     style: {
                                                                                                         marginLeft: 0
                                                                                                     }
                                                                                                 }}
                                                                                             />

                                                                                               }
                                                                                                
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            } else {
                                                                                return <div key={String(sKey).toLowerCase() + " [" + index + "]"} className='row'>
                                                                                    <div className='col-md-12'>
                                                                                        <TextField
                                                                                            label={String(sKey).toLowerCase() + " [" + index + "]"}
                                                                                            value={props.language.detail[key][sKey][index]}
                                                                                            onChange={(event) => props.setValue(key, event.target.value, sKey, index, null)}
                                                                                            autoComplete="off"
                                                                                            fullWidth
                                                                                            margin="normal"
                                                                                            variant="standard"
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                            FormHelperTextProps={{
                                                                                                style: {
                                                                                                    marginLeft: 0
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        })
                                                                    }
                                                                    <div className="separator separator-dashed my-7"></div>
                                                                </>

                                                            } else {
                                                                // object
                                                                return <>
                                                                    <br />
                                                                    <Typography style={{ fontWeight: "bolder" }} className={classes.heading}>{String(sKey).toLocaleLowerCase("tr")}</Typography>
                                                                    {
                                                                        Object.keys(props.language.detail[key][sKey]).filter((xxSItem) => { return String(xxSItem).includes("_") === false }).map((item, index) => {
                                                                            return <div className='row'>
                                                                                <div className='col-md-12'>
                                                                                    <TextField
                                                                                        label={String(item).toLowerCase()}
                                                                                        value={props.language.detail[key][sKey][item]}
                                                                                        onChange={(event) => props.setValue(key, event.target.value, sKey, item, null)}
                                                                                        autoComplete="off"
                                                                                        fullWidth
                                                                                        margin="normal"
                                                                                        variant="standard"
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                        FormHelperTextProps={{
                                                                                            style: {
                                                                                                marginLeft: 0
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                    <div className="separator separator-dashed my-7"></div>
                                                                </>
                                                            }
                                                        }
                                                    })
                                                }

                                            </Tab.Pane>
                                        })
                                    }

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container> : null

                }


            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.language.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.language.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.language.isFetchingActions} onClick={() => {
                    if (props.language.process === "create") {

                    } else if (props.language.process === "update") {
                        props.update(props.auth.user.token, 
                            props.language.selectedLanguage,
                            props.language.detail,
                            props.table.index);
                    }
                }} variant="primary">
                    {
                        props.language.isFetchingActions ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.language.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Diller"
            actionsButtonHidden={true}
            cardIcon="fa fa-tools"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.language.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        language: state.language,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        update,
        tableActions,

    })(LanguagePage);
