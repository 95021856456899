import { combineReducers } from 'redux';
import {
    authReducer,
    usersReducer,
    loginReducer,
    roleReducer,
    titleReducer,
    tableReducer,
    createPasswordReducer,
    colorReducer,
    categoryReducer,
    dashboardReducer,
    productReducer,
    customerReducer,
    feedbackReducer,
    sizeReducer,
    alertReducer,
    bannerReducer,
    templateReducer,
    stockManagementReducer,
    languageReducer,
    customerCommentReducer,
    referenceReducer,
    caseReducer,
} from './importReducers';

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    login: loginReducer,
    role: roleReducer,
    title: titleReducer,
    table: tableReducer,
    createPassword: createPasswordReducer,
    color: colorReducer,
    category: categoryReducer,
    dashboard: dashboardReducer,
    product: productReducer,
    customer: customerReducer,
    feedback: feedbackReducer,
    size: sizeReducer,
    alert: alertReducer,
    banner: bannerReducer,
    template: templateReducer,
    stockManagement: stockManagementReducer,
    language: languageReducer,
    customerComment: customerCommentReducer,
    references: referenceReducer,
    case : caseReducer,
});

export default rootReducer;