const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_FEEDBACKS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_FEEDBACKS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_FEEDBACKS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_FEEDBACKS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_FEEDBACKS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data
            }
        case "ERROR_INSERT_FEEDBACKS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_FEEDBACK_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show
            }
        case "SET_VALUE_FEEDBACK":
            state.detail[action.payload.key] = action.payload.value;
            return {
                ...state     
            }
        case "SET_VALUE_ERROR_FEEDBACK":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_FEEDBACK":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_FEEDBACK":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data
            }
        case "ERROR_DETAIL_FEEDBACK":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_FEEDBACK":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_FEEDBACK":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_UPDATE_FEEDBACK":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_FEEDBACK":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_FEEDBACK":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_FEEDBACK":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
