import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { connect } from 'react-redux';
import {
  connectToSocket
} from "../redux/actions/loginActions";
import {
  toggleAlert
} from "../redux/actions/alertActions";
import {
  DashboardPage
} from "./pages/index";
import CustomAlert from "./helpers/alert/CustomAlert";

const OperationsRoute = lazy(() =>
  import("./pages/all/operation/index")
);

const ProductRoute = lazy(() =>
  import("./pages/all/product/index")
);

const WareHouseRoute = lazy(() =>
  import("./pages/all/warehouse/index")
);

const MailingRoute = lazy(() =>
  import("./pages/all/mailing/index")
);


const CRMRoute = lazy(() =>
  import("./pages/all/crm/index")
);


function BasePage(props) {
  useEffect(() => {
    props.connectToSocket(props.auth.user.token);
    return () => {
      // will unMount
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <CustomAlert
        toggleAlert={props.toggleAlert}
        alertProps={props.alert.alert}
      />
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/operations" component={OperationsRoute} />
        <Route path="/product" component={ProductRoute} />
        <Route path="/crm" component={CRMRoute} />
        <Route path="/mailing" component={MailingRoute} />
        <Route path="/stock" component={WareHouseRoute} />
      </Switch>
    </Suspense>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    alert: state.alert
  }
}

export default connect(mapStateToProps,
  {
    connectToSocket,
    toggleAlert
  })(BasePage);
