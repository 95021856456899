import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,
    addPriceRow,
    removePriceRow,
    addDetailsRow,
    removeDetailsRow,
    setDetailsData,
    addShippingPriceRow,
    removeShippingPriceRow,
    addStockDataRow,
    removeStockDataRow,
    setStockData
} from "../../../../../redux/actions/productActions";
import {
    Button,
    Modal,
    Row,
    Col,
    Tab,
    Nav,
    Tabs,
    Badge
} from "react-bootstrap";
import {
    TextField,
    FormHelperText,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    InputAdornment,
    Switch,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Chip,
    Avatar
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import FileUpload from "../../../../helpers/fileupload/FileUpload";

import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));



function ProductsPage(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "productName.tr",
            text: "Ürün Adı (TR)",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.product.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "productCode",
            text: "Ürün Kodu",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.product.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "isOpenForSale",
            text: "Durum",
            sort: true,
            filter: null,
            formatter: (cell) => {
                return cell === true ? <Badge variant="success" className="mr-1">
                    Satışa Açık
                </Badge> : <Badge variant="warning" className="mr-1">
                    Satışa Kapalı
                </Badge>
            },
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.product.isFetchingDetail || props.product.isFetching} />
        <Modal backdrop="static" size="xl" show={props.product.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.product.process === "create" ? "fa fa-plus" : props.product.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.product.process === "create" ? "Yeni Ürün" : props.product.process === "update" ? "Ürün Düzenle" : "Ürün Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.product.process === "create" || props.product.process === "update" ?
                    <Tab.Container id="left-tabs-example" defaultActiveKey="general">
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general">Genel</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="price">Fiyat & İndirim</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="color">Boyut / Kg Renk Resimler</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="category">Kategori Kombinler</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="descs">Teknik Özellikler</Nav.Link>
                                    </Nav.Item>
                                    {
                                        props.product.process === "update" ?
                                            <Nav.Item>
                                                <Nav.Link eventKey="history">İşlem Geçmişi</Nav.Link>
                                            </Nav.Item> : null
                                    }

                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <Tabs defaultActiveKey="tr">
                                            <Tab eventKey="tr" title="Türkçe">
                                                <TextField
                                                    label="Ürün Adı"
                                                    placeholder="Ürün Adı"
                                                    autoComplete="off"
                                                    error={props.product.productName_error}
                                                    value={props.product.productName}
                                                    onChange={(event) => props.setValue("productName", event.target.value)}
                                                    helperText={props.product.productName_helpertext}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            marginLeft: 0
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    label="Ürün Başlığı"
                                                    placeholder="Ürün Başlığı"
                                                    autoComplete="off"
                                                    error={props.product.productTitle_error}
                                                    value={props.product.productTitle}
                                                    onChange={(event) => props.setValue("productTitle", event.target.value)}
                                                    helperText={props.product.productTitle_helpertext}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            marginLeft: 0
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    label="Ürün Açıklaması"
                                                    placeholder="Ürün Açıklaması"
                                                    autoComplete="off"
                                                    error={props.product.description_error}
                                                    value={props.product.description}
                                                    onChange={(event) => props.setValue("description", event.target.value)}
                                                    helperText={props.product.description_helpertext}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            marginLeft: 0
                                                        }
                                                    }}
                                                />

                                            </Tab>
                                            <Tab eventKey="eng" title="English">
                                                <TextField
                                                    label="Product Name"
                                                    placeholder="Product Name"
                                                    autoComplete="off"
                                                    error={props.product.productNameEng_error}
                                                    value={props.product.productNameEng}
                                                    onChange={(event) => props.setValue("productNameEng", event.target.value)}
                                                    helperText={props.product.productNameEng_helpertext}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            marginLeft: 0
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    label="Product Title"
                                                    placeholder="Product Title"
                                                    autoComplete="off"
                                                    error={props.product.productTitleEng_error}
                                                    value={props.product.productTitleEng}
                                                    onChange={(event) => props.setValue("productTitleEng", event.target.value)}
                                                    helperText={props.product.productTitleEng_helpertext}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            marginLeft: 0
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    label="Product Description"
                                                    placeholder="Product Description"
                                                    autoComplete="off"
                                                    error={props.product.descriptionEng_error}
                                                    value={props.product.descriptionEng}
                                                    onChange={(event) => props.setValue("descriptionEng", event.target.value)}
                                                    helperText={props.product.descriptionEng_helpertext}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            marginLeft: 0
                                                        }
                                                    }}
                                                />

                                            </Tab>
                                        </Tabs>
                                        <TextField
                                            label="Ürün Kodu"
                                            placeholder="Ürün Kodu"
                                            autoComplete="off"
                                            error={props.product.productCode_error}
                                            value={props.product.productCode}
                                            onChange={(event) => props.setValue("productCode", event.target.value)}
                                            helperText={props.product.productCode_helpertext}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            FormHelperTextProps={{
                                                style: {
                                                    marginLeft: 0
                                                }
                                            }}
                                        />

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="price">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 5, marginRight: 5 }} className="row">
                                            <Typography className={classes.heading}>{props.product.freeShipping ? "Ücretli Kargo" : "Ürüne Kargo Ücreti Uygula"}</Typography>
                                            <Switch
                                                color="primary"
                                                checked={props.product.freeShipping}
                                                onChange={(event) => props.setValue("freeShipping", event.target.checked)}
                                                value={props.product.freeShipping}
                                            />
                                        </div>

                                        {
                                            props.product.freeShipping ?
                                                props.product.shippingPrices.map((item, i) => {
                                                    return (
                                                        <div className="row" key={i}>
                                                            <div className="col-md-3">
                                                                <TextField
                                                                    label={"Kargo Ücreti"}
                                                                    placeholder="Kargo Ücreti"
                                                                    autoComplete="off"
                                                                    type="number"
                                                                    error={item.price_error}
                                                                    value={item.price}
                                                                    onChange={(event) => props.setValue("shippingPrices", event.target.value, "price", i, props.product.shippingPrices)}
                                                                    helperText={item.price_helpertext}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">
                                                                            {
                                                                                item.currency === "try" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-lira-sign"></i> :
                                                                                    item.currency === "usd" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-dollar-sign"></i> :
                                                                                        <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-euro-sign"></i>
                                                                            }
                                                                        </InputAdornment>,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    FormHelperTextProps={{
                                                                        style: {
                                                                            marginLeft: 0
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <FormControl style={{ marginTop: 16 }} className="form-control" variant="outlined">
                                                                    <InputLabel>
                                                                        Para Birimi
                                                                    </InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        error={item.currency_error}
                                                                        value={item.currency}
                                                                        onChange={(event) => props.setValue("shippingPrices", event.target.value, "currency", i, props.product.shippingPrices)}
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={90}
                                                                            />
                                                                        }
                                                                    >
                                                                        <MenuItem disabled={i === 0 ? false : true} value={"try"}>TL</MenuItem>
                                                                        <MenuItem disabled={i === 0 ? true : false} value={"usd"}>USD</MenuItem>
                                                                        <MenuItem disabled={i === 0 ? true : false} value={"eur"}>EUR</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="col-md-2">
                                                                {
                                                                    i === 0 ?
                                                                        <Button disabled={props.product.shippingPrices.length === 3 ? true : false} onClick={() => props.addShippingPriceRow(props.product.shippingPrices)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </Button> : null
                                                                }
                                                                {
                                                                    i > 0 ?
                                                                        <Button className="btn btn-danger" onClick={() => props.removeShippingPriceRow(i)}>
                                                                            <i className="fa fa-times"></i>
                                                                        </Button> : null
                                                                }
                                                            </div>

                                                        </div>
                                                    )
                                                }) : null
                                        }
                                        <div className="separator separator-dashed my-7"></div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 5, marginRight: 5 }} className="row">
                                            <Typography className={classes.heading}>{props.product.hasDiscount ? "İndirimli Ürün" : "Ürüne İndirim Uygula"}</Typography>
                                            <Switch
                                                color="primary"
                                                checked={props.product.hasDiscount}
                                                onChange={(event) => props.setValue("hasDiscount", event.target.checked)}
                                                value={props.product.hasDiscount}
                                            />
                                        </div>

                                        {
                                            props.product.hasDiscount ?
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 5, marginRight: 5 }} className="row">
                                                    <Typography className={classes.heading}>İndirim Tipi</Typography>
                                                    <Select
                                                        value={props.product.discountType}
                                                        onChange={(event) => props.setValue("discountType", event.target.value)}

                                                    >
                                                        <MenuItem value={"price"}>Fiyat</MenuItem>
                                                        <MenuItem value={"percent"}>Yüzde (%)</MenuItem>

                                                    </Select>
                                                </div> : null

                                        }

                                        <div className="separator separator-dashed my-7"></div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 5, marginRight: 5 }} className="row">
                                            <Typography className={classes.heading}>{props.product.isOpenForSale ? "Ürün Satışa Açık" : "Ürünü Satışa Aç"}</Typography>
                                            <Switch
                                                color="primary"
                                                checked={props.product.isOpenForSale}
                                                onChange={(event) => props.setValue("isOpenForSale", event.target.checked)}
                                                value={props.product.isOpenForSale}
                                            />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 5, marginRight: 5 }} className="row">
                                            <Typography className={classes.heading}>{props.product.newProduct ? "Yeni Ürün" : "Var Olan Ürün"}</Typography>
                                            <Switch
                                                color="primary"
                                                checked={props.product.newProduct}
                                                onChange={(event) => props.setValue("newProduct", event.target.checked)}
                                                value={props.product.newProduct}
                                            />
                                        </div>


                                        <div className="separator separator-dashed my-7"></div>

                                        {
                                            props.product.prices.map((item, i) => {
                                                return (
                                                    <div key={i} className="row">
                                                        <div className="col-md-3">
                                                            <TextField
                                                                label={"Fiyat"}
                                                                placeholder="Fiyat"
                                                                autoComplete="off"
                                                                type="number"
                                                                error={item.normalPrice_error}
                                                                value={item.normalPrice}
                                                                onChange={(event) => props.setValue("prices", event.target.value, "normalPrice", i, props.product.prices)}
                                                                helperText={item.normalPrice_helpertext}
                                                                fullWidth
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">
                                                                        {
                                                                            item.currency === "try" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-lira-sign"></i> :
                                                                                item.currency === "usd" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-dollar-sign"></i> :
                                                                                    <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-euro-sign"></i>
                                                                        }
                                                                    </InputAdornment>,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        marginLeft: 0
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            props.product.hasDiscount ?
                                                                <div className="col-md-3">
                                                                    <TextField
                                                                        label={"İndirimli Fiyat"}
                                                                        placeholder="İndirimli Fiyat"
                                                                        autoComplete="off"
                                                                        type="number"
                                                                        error={item.discountedPrice_error}
                                                                        value={item.discountedPrice}
                                                                        onChange={(event) => props.setValue("prices", event.target.value, "discountedPrice", i, props.product.prices)}
                                                                        helperText={item.discountedPrice_helpertext}
                                                                        fullWidth
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">
                                                                                {
                                                                                    item.currency === "try" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-lira-sign"></i> :
                                                                                        item.currency === "usd" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-dollar-sign"></i> :
                                                                                            <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-euro-sign"></i>
                                                                                }
                                                                            </InputAdornment>,
                                                                        }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            style: {
                                                                                marginLeft: 0
                                                                            }
                                                                        }}
                                                                    />
                                                                </div> : null
                                                        }

                                                        {
                                                            props.product.hasDiscount ?
                                                                <div className="col-md-2">
                                                                    <TextField
                                                                        label="İndirim"
                                                                        placeholder=""
                                                                        autoComplete="off"
                                                                        type="number"
                                                                        error={item.discount_error}
                                                                        value={item.discount}
                                                                        onChange={(event) => props.setValue("prices", event.target.value, "discount", i, props.product.prices)}
                                                                        helperText={item.discount_helpertext}
                                                                        fullWidth
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment style={{ marginBottom: 2 }} position="start">
                                                                                {
                                                                                    props.product.discountType === "percent" ? "%" :
                                                                                        item.currency === "try" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-lira-sign"></i> :
                                                                                            item.currency === "usd" ? <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-dollar-sign"></i> :
                                                                                                <i style={{ fontSize: "smaller", marginBottom: 2 }} className="fa fa-euro-sign"></i>

                                                                                }
                                                                            </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            style: {
                                                                                marginLeft: 0
                                                                            }
                                                                        }}
                                                                    />
                                                                </div> : null
                                                        }
                                                        <div className="col-md-2">
                                                            <FormControl style={{ marginTop: 16 }} className="form-control" variant="outlined">
                                                                <InputLabel>
                                                                    Para Birimi
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    error={item.currency_error}
                                                                    value={item.currency}
                                                                    onChange={(event) => props.setValue("prices", event.target.value, "currency", i, props.product.prices)}
                                                                    input={
                                                                        <OutlinedInput
                                                                            labelWidth={90}
                                                                        />
                                                                    }
                                                                >
                                                                    <MenuItem disabled={i === 0 ? false : true} value={"try"}>TL</MenuItem>
                                                                    <MenuItem disabled={i === 0 ? true : false} value={"usd"}>USD</MenuItem>
                                                                    <MenuItem disabled={i === 0 ? true : false} value={"eur"}>EUR</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="col-md-2">
                                                            {
                                                                i === 0 ?
                                                                    <Button disabled={props.product.prices.length === 3 ? true : false} onClick={() => props.addPriceRow(props.product.prices, props.product.discountType)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </Button> : null
                                                            }
                                                            {
                                                                i > 0 ?
                                                                    <Button className="btn btn-danger" onClick={() => props.removePriceRow(i)}>
                                                                        <i className="fa fa-times"></i>
                                                                    </Button> : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="color">

                                        {
                                            props.product.stockData.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div style={{ marginBottom: 35 }} className="row">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="col-md-5">
                                                                <FormControl className="form-control" variant="outlined">
                                                                    <InputLabel>
                                                                        Renk
                                                                    </InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        error={item.color_error}
                                                                        value={item.color}
                                                                        onChange={(event) => props.setStockData("color", "stockData", i, event.target.value)}
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={32}

                                                                            />
                                                                        }
                                                                    >
                                                                        <MenuItem disabled value={""}>{props.product.colors.length > 0 ? "Renk Seçiniz" : "Renk Bulunamadı"}</MenuItem>
                                                                        {
                                                                            props.product.colors.map((item, i) => {
                                                                                return <MenuItem disabled={item.selected === true ? true : false} key={i} value={item.id}>{item.colorName}</MenuItem>
                                                                            })}

                                                                    </Select>
                                                                    <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{item.color_helpertext}</FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="col-md-5">
                                                                <FormControl className="form-control" variant="outlined">
                                                                    <InputLabel>
                                                                        Mevcut Boyutlar
                                                                    </InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        multiple
                                                                        error={item.selectedSizes_error}
                                                                        value={item.selectedSizes}
                                                                        onChange={(event) => props.setStockData("selectedSizes", "stockData", i, event.target.value)}
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={107}

                                                                            />
                                                                        }
                                                                    >
                                                                        <MenuItem disabled value={""}>{props.product.sizes.length > 0 ? "Boyut / Kg Seçiniz" : "Boyut / Kg Bulunamadı"}</MenuItem>
                                                                        {
                                                                            props.product.sizes.map((item, i) => {
                                                                                return <MenuItem key={i} value={item.id}>{item.sizeCode}</MenuItem>
                                                                            })}

                                                                    </Select>
                                                                    <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{item.selectedSizes_helpertext}</FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="col-md-2">
                                                                {
                                                                    i === 0 ?
                                                                        <Button onClick={() => props.addStockDataRow(props.product.colors, props.product.stockData, props.product.sizes)} disabled={props.product.stockData[i].color === "" ? true : false}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </Button> : null
                                                                }
                                                                {
                                                                    i > 0 ?
                                                                        <Button onClick={() => props.removeStockDataRow(i)} className="btn btn-danger">
                                                                            <i className="fa fa-times"></i>
                                                                        </Button> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row col-md-12">
                                                            <FileUpload
                                                                {...props}
                                                                stock={true}
                                                                order={i}
                                                                uploadedImages={item.images} />

                                                        </div>
                                                        {
                                                            i === props.product.stockData.length - 1 ? null :
                                                                <div className="separator separator-dashed my-7"></div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="category">
                                        <FormControl style={{ marginBottom: props.product.category_error ? 10 : 0 }} className="form-control" variant="outlined">
                                            <InputLabel>
                                                Kategori
                                            </InputLabel>
                                            <Select
                                                multiple
                                                fullWidth
                                                error={props.product.category_error}
                                                value={props.product.category}
                                                onChange={(event) => props.setValue("category", event.target.value)}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={58}
                                                    />
                                                }
                                            >
                                                <MenuItem disabled value={""}>{props.product.categories.length > 0 ? "Kategori Seçiniz" : "Kategori Bulunamadı"}</MenuItem>
                                                {
                                                    props.product.categories.map((item, i) => {
                                                        return <MenuItem key={i} value={item.id}>{item.categoryName.tr}</MenuItem>
                                                    })}

                                            </Select>
                                            <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{props.product.category_helpertext}</FormHelperText>
                                        </FormControl>
                                        <FormControl style={{ marginTop: 30 }} className="form-control" variant="outlined">
                                            <InputLabel>
                                                Ürüne Kombin Ürünler
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                multiple
                                                error={props.product.combinedWith_error}
                                                value={props.product.combinedWith}
                                                onChange={(event) => props.setValue("combinedWith", event.target.value)}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={140}
                                                    />
                                                }
                                            >
                                                <MenuItem disabled value={""}>{props.product.data.length > 0 ? "Ürün Seçiniz" : "Ürün Bulunamadı"}</MenuItem>
                                                {
                                                    props.product.data.map((item, i) => {
                                                        return <MenuItem key={i} value={item.id}>{item.productName.tr}</MenuItem>
                                                    })}

                                            </Select>
                                            <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{props.product.combinedWith_helpertext}</FormHelperText>
                                        </FormControl>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="descs">
                                        <Tabs defaultActiveKey="trDesc">
                                            <Tab eventKey="trDesc" title="Türkçe">
                                                <br />
                                                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Typography className={classes.heading}>Özellikler</Typography>

                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <Button style={{ marginBottom: 10 }} onClick={() => props.addDetailsRow("productDetails", "features")} className="btn btn-info" block>
                                                            <i className="fa fa-plus"></i>
                                                        </Button>

                                                        {
                                                            props.product.productDetails.features.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-10">
                                                                            <TextField
                                                                                label={"*Özellik"}
                                                                                autoComplete="off"
                                                                                error={item.value_error}
                                                                                value={item.value}
                                                                                onChange={(event) => props.setDetailsData("productDetails", "features", i, event.target.value)}
                                                                                helperText={item.value_helpertext}
                                                                                fullWidth
                                                                                margin="normal"
                                                                                variant="filled"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        marginLeft: 0
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} className="col-md-2">
                                                                            <Button onClick={() => props.removeDetailsRow("productDetails", "features", i)} className="btn btn-danger">
                                                                                <i className="fa fa-times"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>

                                                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Typography className={classes.heading}>Malzemeler</Typography>

                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <Button style={{ marginBottom: 10 }} onClick={() => props.addDetailsRow("productDetails", "materials")} className="btn btn-info" block>
                                                            <i className="fa fa-plus"></i>
                                                        </Button>

                                                        {
                                                            props.product.productDetails.materials.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-10">
                                                                            <TextField
                                                                                label={"*Malzeme"}
                                                                                autoComplete="off"
                                                                                error={item.value_error}
                                                                                value={item.value}
                                                                                onChange={(event) => props.setDetailsData("productDetails", "materials", i, event.target.value)}
                                                                                helperText={item.value_helpertext}
                                                                                fullWidth
                                                                                margin="normal"
                                                                                variant="filled"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        marginLeft: 0
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} className="col-md-2">
                                                                            <Button onClick={() => props.removeDetailsRow("productDetails", "materials", i)} className="btn btn-danger">
                                                                                <i className="fa fa-times"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>


                                                <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel3bh-content"
                                                        id="panel3bh-header"
                                                    >
                                                        <Typography className={classes.heading}>Boyut & Esneklik</Typography>

                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <Button style={{ marginBottom: 10 }} onClick={() => props.addDetailsRow("productDetails", "sizeAndFit")} className="btn btn-info" block>
                                                            <i className="fa fa-plus"></i>
                                                        </Button>

                                                        {
                                                            props.product.productDetails.sizeAndFit.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-10">
                                                                            <TextField
                                                                                label={"*Boyut & Esneklik"}
                                                                                autoComplete="off"
                                                                                error={item.value_error}
                                                                                value={item.value}
                                                                                onChange={(event) => props.setDetailsData("productDetails", "sizeAndFit", i, event.target.value)}
                                                                                helperText={item.value_helpertext}
                                                                                fullWidth
                                                                                margin="normal"
                                                                                variant="filled"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        marginLeft: 0
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} className="col-md-2">
                                                                            <Button onClick={() => props.removeDetailsRow("productDetails", "sizeAndFit", i)} className="btn btn-danger">
                                                                                <i className="fa fa-times"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>

                                            </Tab>
                                            <Tab eventKey="engDesc" title="English">
                                                <br />
                                                <ExpansionPanel expanded={expanded === 'panel1Eng'} onChange={handleChange('panel1Eng')}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Typography className={classes.heading}>Product Details</Typography>

                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <Button style={{ marginBottom: 10 }} onClick={() => props.addDetailsRow("productDetailsEng", "features")} className="btn btn-info" block>
                                                            <i className="fa fa-plus"></i>
                                                        </Button>
                                                        {
                                                            props.product.productDetailsEng.features.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-10">
                                                                            <TextField
                                                                                label={"*Feature"}

                                                                                autoComplete="off"
                                                                                error={item.value_error}
                                                                                value={item.value}
                                                                                onChange={(event) => props.setDetailsData("productDetailsEng", "features", i, event.target.value)}
                                                                                helperText={item.value_helpertext}
                                                                                fullWidth
                                                                                margin="normal"
                                                                                variant="filled"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        marginLeft: 0
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} className="col-md-2">
                                                                            <Button onClick={() => props.removeDetailsRow("productDetailsEng", "features", i)} className="btn btn-danger">
                                                                                <i className="fa fa-times"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>

                                                <ExpansionPanel expanded={expanded === 'panel2Eng'} onChange={handleChange('panel2Eng')}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Typography className={classes.heading}>Materials</Typography>

                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <Button style={{ marginBottom: 10 }} onClick={() => props.addDetailsRow("productDetailsEng", "materials")} className="btn btn-info" block>
                                                            <i className="fa fa-plus"></i>
                                                        </Button>
                                                        {
                                                            props.product.productDetailsEng.materials.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-10">
                                                                            <TextField
                                                                                label={"*Material"}

                                                                                autoComplete="off"
                                                                                error={item.value_error}
                                                                                value={item.value}
                                                                                onChange={(event) => props.setDetailsData("productDetailsEng", "materials", i, event.target.value)}
                                                                                helperText={item.value_helpertext}
                                                                                fullWidth
                                                                                margin="normal"
                                                                                variant="filled"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        marginLeft: 0
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} className="col-md-2">
                                                                            <Button onClick={() => props.removeDetailsRow("productDetailsEng", "materials", i)} className="btn btn-danger">
                                                                                <i className="fa fa-times"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>



                                                <ExpansionPanel expanded={expanded === 'panel3Eng'} onChange={handleChange('panel3Eng')}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel3bh-content"
                                                        id="panel3bh-header"
                                                    >
                                                        <Typography className={classes.heading}>Size And Fit</Typography>

                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <Button style={{ marginBottom: 10 }} onClick={() => props.addDetailsRow("productDetailsEng", "sizeAndFit")} className="btn btn-info" block>
                                                            <i className="fa fa-plus"></i>
                                                        </Button>

                                                        {
                                                            props.product.productDetailsEng.sizeAndFit.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-10">
                                                                            <TextField
                                                                                label={"Size & Fit"}
                                                                                autoComplete="off"
                                                                                error={item.value_error}
                                                                                value={item.value}
                                                                                onChange={(event) => props.setDetailsData("productDetailsEng", "sizeAndFit", i, event.target.value)}
                                                                                helperText={item.value_helpertext}
                                                                                fullWidth
                                                                                margin="normal"
                                                                                variant="filled"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        marginLeft: 0
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} className="col-md-2">
                                                                            <Button onClick={() => props.removeDetailsRow("productDetailsEng", "sizeAndFit", i)} className="btn btn-danger">
                                                                                <i className="fa fa-times"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }


                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Tab>
                                        </Tabs>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="history">
                                        <div className="separator separator-dashed my-7"></div>
                                        {props.product.process === "update" && props.product.detail !== null ?
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>İŞLEM GEÇMİŞİ</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        props.product.detail.createdBy !== null ?
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Chip
                                                                        style={{ marginLeft: -5 }}
                                                                        avatar={<Avatar>{String(props.product.detail.createdBy.name[0]).toUpperCase() + String(props.product.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                        label={props.product.detail.createdBy.username}
                                                                    />
                                                                    <br />
                                                                    <small>{moment(props.product.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                                    <small>{moment(props.product.detail.createdAt).fromNow()}</small>
                                                                </TableCell>
                                                            </TableRow> : null

                                                    }
                                                    {
                                                        props.product.detail.updatedBy !== undefined && props.product.detail.updatedBy !== null ?
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Chip
                                                                        style={{ marginLeft: -5 }}
                                                                        avatar={<Avatar>{String(props.product.detail.updatedBy.name[0]).toUpperCase() + String(props.product.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                        label={props.product.detail.updatedBy.username}
                                                                    /><br />
                                                                    <small>{moment(props.product.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                                    <small>{moment(props.product.detail.updatedAt).fromNow()}</small>
                                                                </TableCell>
                                                            </TableRow> : null
                                                    }
                                                </TableBody>
                                            </Table>

                                            : null
                                        }

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container> : props.table.row !== null && props.table.row.productName ?
                        <>
                            <b>{'"'}{props.table.row.productName.tr}{'" '}</b> isimli ürünü silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.product.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.product.process)}>
                    İptal
                </Button>
                <Button onClick={() => {
                    if (props.product.process === "create") {
                        props.createNew(props.auth.user.token, [
                            {
                                key: "productName",
                                value: props.product.productName,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productTitle",
                                value: props.product.productTitle,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "description",
                                value: props.product.description,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productCode",
                                value: props.product.productCode,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productNameEng",
                                value: props.product.productNameEng,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productTitleEng",
                                value: props.product.productTitleEng,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "descriptionEng",
                                value: props.product.descriptionEng,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "category",
                                value: props.product.category,
                                required: true,
                                type: "Array"
                            },
                            {
                                key: "combinedWith",
                                value: props.product.combinedWith,
                                required: false,
                                type: "Array"
                            },
                            {
                                key: "stockData",
                                value: props.product.stockData,
                                required: true,
                                type: "Array"
                            },
                            {
                                key: "hasDiscount",
                                value: props.product.hasDiscount,
                                required: false,
                                type: "Boolean"
                            },
                            {
                                key: "discountType",
                                value: props.product.discountType,
                                required: false,
                                type: "Boolean"

                            },
                            {
                                key: "freeShipping",
                                value: props.product.freeShipping,
                                required: false,
                                type: "Boolean"
                            },
                            {
                                key: "isOpenForSale",
                                value: props.product.isOpenForSale,
                                required: false,
                                type: "Boolean"
                            },
                            {
                                key: "productDetails",
                                value: props.product.productDetails,
                                required: false,
                                type: "Object"
                            },
                            {
                                key: "productDetailsEng",
                                value: props.product.productDetailsEng,
                                required: false,
                                type: "Object"
                            },
                            {
                                key: "prices",
                                value: props.product.prices,
                                required: false,
                                type: "Array"
                            },
                            {
                                key: "shippingPrices",
                                value: props.product.shippingPrices,
                                required: false,
                                type: "Array"
                            }

                        ])
                    } else if (props.product.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id, [
                            {
                                key: "productName",
                                value: props.product.productName,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productTitle",
                                value: props.product.productTitle,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "description",
                                value: props.product.description,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productCode",
                                value: props.product.productCode,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productNameEng",
                                value: props.product.productNameEng,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "productTitleEng",
                                value: props.product.productTitleEng,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "descriptionEng",
                                value: props.product.descriptionEng,
                                required: true,
                                type: "String"
                            },
                            {
                                key: "category",
                                value: props.product.category,
                                required: true,
                                type: "Array"
                            },
                            {
                                key: "combinedWith",
                                value: props.product.combinedWith,
                                required: false,
                                type: "Array"
                            },
                            {
                                key: "stockData",
                                value: props.product.stockData,
                                required: true,
                                type: "Array"
                            },
                            {
                                key: "hasDiscount",
                                value: props.product.hasDiscount,
                                required: false,
                                type: "Boolean"
                            },
                            {
                                key: "discountType",
                                value: props.product.discountType,
                                required: false,
                                type: "Boolean"

                            },
                            {
                                key: "freeShipping",
                                value: props.product.freeShipping,
                                required: false,
                                type: "Boolean"
                            },
                            {
                                key: "isOpenForSale",
                                value: props.product.isOpenForSale,
                                required: false,
                                type: "Boolean"
                            },
                            {
                                key: "productDetails",
                                value: props.product.productDetails,
                                required: false,
                                type: "Object"
                            },
                            {
                                key: "productDetailsEng",
                                value: props.product.productDetailsEng,
                                required: false,
                                type: "Object"
                            },
                            {
                                key: "prices",
                                value: props.product.prices,
                                required: false,
                                type: "Array"
                            },
                            {
                                key: "shippingPrices",
                                value: props.product.shippingPrices,
                                required: false,
                                type: "Array"
                            },
                            {
                                key: "newProduct",
                                value: props.product.newProduct,
                                required: false
                            }

                        ], props.table.index)
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.product.isFetchingActions} variant="primary">
                    {
                        props.product.isFetchingActions ?
                            "İşleniyor..." :
                            props.product.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            token={props.auth.user.token}
            refreshTable={props.getAll}
            actionsButtonHidden={false}
            handleModal={props.toggleModal}
            title="Tüm Ürünler"
            buttonTitle="Yeni Ürün"
            cardIcon="fa fa-wallet"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.product.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        product: state.product,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        update,
        deleteEntity,
        tableActions,
        addPriceRow,
        removePriceRow,
        addDetailsRow,
        removeDetailsRow,
        setDetailsData,
        addShippingPriceRow,
        removeShippingPriceRow,
        addStockDataRow,
        removeStockDataRow,
        setStockData
    })(ProductsPage);
