import { globalURL, fetchApiHeaders } from "../../app/constants/constants";

const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_LANGUAGE_MODAL",
        payload: {
            show: show,
            process: process
        }
    });

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}


const setValue = (key, value, subKey, index, kItem) => dispatch => {
    dispatch({
        type: "SET_VALUE_LANGUAGE",
        payload: {
            key: key,
            value: value,
            subKey: subKey,
            index: index,
            kItem: kItem
        }
    })
}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_LANGUAGES"
    })
    fetch(globalURL + "/language/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (languages) => {
            if (languages.success) {
                dispatch({
                    type: "FETCH_ALL_LANGUAGES",
                    payload: languages.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ALL_LANGUAGES",
                    payload: languages
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ALL_LANGUAGES",
                payload: error
            })
        })
}


const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_LANGUAGE"
        })
        fetch(globalURL + "/language/getByCode", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_LANGUAGE",
                        payload: detail,
                        selected: id
                    })
                    dispatch({
                        type: "TOGGLE_LANGUAGE_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_LANGUAGE",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_LANGUAGE",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_LANGUAGE_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const update = (token, id, data, rowIndex) => dispatch => {
    dispatch({
        type: "GET_UPDATE_LANGUAGE"
    });

    // update
    fetch(globalURL + "/language/update", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: id,
            data: data
        })
    })
        .then((result) => result.json())
        .then(async (update) => {
            if (update.success) {
                dispatch({
                    type: "FETCH_UPDATE_LANGUAGE",
                    payload: update,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: id + " dili başarıyla güncellendi.",
                        type: "success"
                    }
                });
            } else {
                if (update.field) {
                    dispatch({
                        type: "SET_VALUE_ERROR_LANGUAGE",
                        payload: {
                            key: update.field,
                            helpertext: update.errorMessage
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });
                }
                dispatch({
                    type: "ERROR_UPDATE_LANGUAGE",
                    payload: update
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_UPDATE_LANGUAGE",
                payload: error
            })
        })

}



export {
    toggleModal,
    setValue,
    getAll,
    update,
    tableActions,
}