import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions
} from "../../../../../redux/actions/caseActions";
import {
    Button,
    Modal,
    Badge,
    Tabs,
    Tab
} from "react-bootstrap";
import {
    TextField,
    FormControlLabel,
    FormHelperText,
    Switch,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import { globalURL } from "../../../../constants/constants";
import Loading from "../../../../helpers/loading/Loading";
import FileUpload from "../../../../helpers/fileupload/FileUpload";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';


function CasesPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "title.tr",
            text: "Başlık (TR)",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.case.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "description.tr",
            text: "Açıklama (TR)",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.case.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.case.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.case.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.case.process === "create" ? "fa fa-plus" : props.case.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.case.process === "create" ? "Yeni Çalışma" : props.case.process === "update" ? "Çalışma Detay / Düzenle" : "Çalışma Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.case.process === "create" || props.case.process === "update" ?
                    <div>
                        <Tabs defaultActiveKey="tr" id="uncontrolled-tab-example">
                            <Tab eventKey="tr" title="Türkçe">
                                <TextField
                                    label="Başlık"
                                    placeholder="Başlık"
                                    autoComplete="off"
                                    error={props.case.title_error}
                                    value={props.case.title}
                                    onChange={(event) => props.setValue("title", event.target.value)}
                                    helperText={props.case.title_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Açıklama"
                                    autoComplete="off"
                                    placeholder="Açıklama"
                                    error={props.case.description_error}
                                    value={props.case.description}
                                    onChange={(event) => props.setValue("description", event.target.value)}
                                    helperText={props.case.description_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Yazı"
                                    autoComplete="off"
                                    placeholder="Yazı"
                                    error={props.case.text_error}
                                    value={props.case.text}
                                    onChange={(event) => props.setValue("text", event.target.value)}
                                    helperText={props.case.text_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Alt Yazı"
                                    autoComplete="off"
                                    placeholder="Alt Yazı"
                                    error={props.case.helperText_error}
                                    value={props.case.helperText}
                                    onChange={(event) => props.setValue("helperText", event.target.value)}
                                    helperText={props.case.helperText_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />

                                <TextField
                                    label="Kategori"
                                    autoComplete="off"
                                    placeholder="Kategori"
                                    error={props.case.category_error}
                                    value={props.case.category}
                                    onChange={(event) => props.setValue("category", event.target.value)}
                                    helperText={props.case.category_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                            </Tab>
                            <Tab eventKey="eng" title="English">
                                <TextField
                                    label="Title"
                                    placeholder="Title"
                                    autoComplete="off"
                                    error={props.case.title_eng_error}
                                    value={props.case.title_eng}
                                    onChange={(event) => props.setValue("title_eng", event.target.value)}
                                    helperText={props.case.title_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Description"
                                    autoComplete="off"
                                    placeholder="Description"
                                    error={props.case.description_eng_error}
                                    value={props.case.description_eng}
                                    onChange={(event) => props.setValue("description_eng", event.target.value)}
                                    helperText={props.case.description_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Text"
                                    autoComplete="off"
                                    placeholder="Text"
                                    error={props.case.text_eng_error}
                                    value={props.case.text_eng}
                                    onChange={(event) => props.setValue("text_eng", event.target.value)}
                                    helperText={props.case.text_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Helper Text"
                                    autoComplete="off"
                                    placeholder="Helper Text"
                                    error={props.case.helperText_eng_error}
                                    value={props.case.helperText_eng}
                                    onChange={(event) => props.setValue("helperText_eng", event.target.value)}
                                    helperText={props.case.helperText_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Category"
                                    autoComplete="off"
                                    placeholder="Category"
                                    error={props.case.category_eng_error}
                                    value={props.case.category_eng}
                                    onChange={(event) => props.setValue("category_eng", event.target.value)}
                                    helperText={props.case.category_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                            </Tab>
                        </Tabs>

                        <div style={{ marginTop: 15 }}>
                            <FileUpload
                                {...props}
                                multiple={true}
                                dropzoneText={"Resim(ler) Seçiniz 967*683"}
                                stateKey={"image"}
                                uploadedImages={props.case.image}
                            />
                        </div>
                        {
                            props.case.process === "update" && props.case.detail !== null ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ÇALIŞMA DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.case.detail.createdBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.case.detail.createdBy.name[0]).toUpperCase() + String(props.case.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.case.detail.createdBy.username}
                                                            />
                                                            <br />
                                                            <small>{moment(props.case.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.case.detail.createdAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null

                                            }

                                            {
                                                props.case.detail.updatedBy !== undefined && props.case.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.case.detail.updatedBy.name[0]).toUpperCase() + String(props.case.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.case.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.case.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.case.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }

                    </div> : props.table.row !== null && props.table.row.title ?
                        <>
                            <b>{'"'}{props.table.row.title.tr}{'" '}</b> başlıklı caseı silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.case.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.case.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.case.isFetchingActions} onClick={() => {
                    if (props.case.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "title",
                                    value: props.case.title,
                                    required: true
                                },
                                {
                                    key: "description",
                                    value: props.case.description,
                                    required: true
                                },
                                {
                                    key: "text",
                                    value: props.case.text,
                                    required: true
                                },
                                {
                                    key: "image",
                                    value: props.case.image,
                                    required: false
                                },
                                {
                                    key: "title_eng",
                                    value: props.case.title_eng,
                                    required: true
                                },
                                {
                                    key: "description_eng",
                                    value: props.case.description_eng,
                                    required: true
                                },
                                {
                                    key: "text_eng",
                                    value: props.case.text_eng,
                                    required: true
                                },
                                {
                                    key: "category",
                                    value: props.case.category,
                                    required: true
                                },
                                {
                                    key: "helperText",
                                    value: props.case.helperText,
                                    required: true
                                },
                                {
                                    key: "category_eng",
                                    value: props.case.category_eng,
                                    required: true
                                },
                                {
                                    key: "helperText_eng",
                                    value: props.case.helperText_eng,
                                    required: true
                                },
                            ]);
                    } else if (props.case.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "title",
                                    value: props.case.title,
                                    required: true
                                },
                                {
                                    key: "description",
                                    value: props.case.description,
                                    required: true
                                },
                                {
                                    key: "text",
                                    value: props.case.text,
                                    required: true
                                },
                                {
                                    key: "image",
                                    value: props.case.image,
                                    required: false
                                },
                                {
                                    key: "title_eng",
                                    value: props.case.title_eng,
                                    required: true
                                },
                                {
                                    key: "description_eng",
                                    value: props.case.description_eng,
                                    required: true
                                },
                                {
                                    key: "text_eng",
                                    value: props.case.text_eng,
                                    required: true
                                },
                                {
                                    key: "category",
                                    value: props.case.category,
                                    required: true
                                },
                                {
                                    key: "helperText",
                                    value: props.case.helperText,
                                    required: true
                                },
                                {
                                    key: "category_eng",
                                    value: props.case.category_eng,
                                    required: true
                                },
                                {
                                    key: "helperText_eng",
                                    value: props.case.helperText_eng,
                                    required: true
                                },

                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.case.isFetchingActions ?
                            <span className="spinner spinner-white"></span> :
                            props.case.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Çalışmalar"
            buttonTitle="Yeni Ekle"
            cardIcon="fa fa-list-ol"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.case.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        case: state.case,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        tableActions,
        update,
        deleteEntity,

    })(CasesPage);
