import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    name: "",
    surname: "",
    title: "",
    company: "",
    commentText: "",
    point: 0,
    name_helpertext: "",
    surname_helpertext: "",
    title_helpertext: "",
    company_helpertext: "",
    commentText_helpertext: "",
    point_helpertext: "",
    image: [],
    image_helpertext: "",
    image_error: false,
    name_error: false,
    surname_error: false,
    title_error: false,
    company_error: false,
    commentText_error: false,
    point_error: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_CUSTOMERCOMMENTS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_CUSTOMERCOMMENTS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_CUSTOMERCOMMENTS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_CUSTOMERCOMMENTS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_CUSTOMERCOMMENTS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data,
                name: "",
                surname: "",
                title: "",
                company: "",
                commentText: "",
                point: 0,
                image: []
            }
        case "ERROR_INSERT_CUSTOMERCOMMENTS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_CUSTOMERCOMMENT_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                name: action.payload.show ? state.name : "",
                surname: action.payload.show ? state.surname : "",
                title: action.payload.show ? state.title : "",
                company: action.payload.show ? state.company : "",
                point: action.payload.show ? state.point : "",
                commentText: action.payload.show ? state.commentText : "",
                image: action.payload.process === "create" ? [] : state.image,
                name_helpertext: "",
                surname_helpertext: "",
                title_helpertext: "",
                company_helpertext: "",
                commentText_helpertext: "",
                point_helpertext: "",
                image_helpertext: "",
                image_error: false,
                name_error: false,
                surname_error: false,
                title_error: false,
                company_error: false,
                commentText_error: false,
                point_error: false,
            }
        case "SET_VALUE_CUSTOMERCOMMENT":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingDetail: true,
                isFetchingActions: false,
                error: false
            }
        case "FETCH_DETAIL_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                name: action.payload.data.name,
                surname: action.payload.data.surname,
                title: action.payload.data.title,
                company: action.payload.data.company,
                point: action.payload.data.point,
                commentText: action.payload.data.commentText,
                image: action.payload.data.image !== null ? [globalURL + "/images/" + action.payload.data.image.name] : []
            }
        case "ERROR_DETAIL_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_CUSTOMERCOMMENT":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                name: "",
                surname: "",
                title: "",
                company: "",
                commentText: "",
                point: 0,
                image: [],
                name_helpertext: "",
                surname_helpertext: "",
                title_helpertext: "",
                company_helpertext: "",
                commentText_helpertext: "",
                point_helpertext: "",
                image_helpertext: "",
                image_error: false,
                name_error: false,
                surname_error: false,
                title_error: false,
                company_error: false,
                commentText_error: false,
                point_error: false,
            }
        case "ERROR_UPDATE_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_CUSTOMERCOMMENT":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_CUSTOMERCOMMENT":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }

        default:
            return state
    }

}
