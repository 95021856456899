import DashboardPage from "./all/dashboard/DashboardPage";

//operation
import Users from "./all/operation/all/Users";
import Roles from "./all/operation/all/Roles";
import Titles from "./all/operation/all/Titles";
import Banners from "./all/operation/all/Banner";
import Languages from "./all/operation/all/Language";
import CustomerComments from "./all/operation/all/CustomerComments";
import References from "./all/operation/all/References";
import Cases from "./all/operation/all/Cases";

//product
import AllProducts from "./all/product/all/AllProducts";
import Size from "./all/product/all/Size";
import Color from "./all/product/all/Color";
import Category from "./all/product/all/Category";

//stok
import StockManagement from "./all/warehouse/all/StockManagement";
import SearchStock from "./all/warehouse/all/SearchStock";

// CRM
import AllCustomers from "./all/crm/all/AllCustomers";
import BlackList from "./all/crm/all/BlackList";
import CustomerDemands from "./all/crm/all/CustomerDemands";

// Mailing
import MailingFullList from "./all/mailing/all/MailingFullList";
import MailingBlackList from "./all/mailing/all/MailingBlackList";
import MailingQuitList from "./all/mailing/all/MailingQuitList";
import SpamList from "./all/mailing/all/SpamList";
import Templates from "./all/mailing/all/Templates";


export {
    DashboardPage,
    Users,
    AllProducts,
    Size,
    AllCustomers,
    BlackList,
    CustomerDemands,
    MailingFullList,
    MailingBlackList,
    MailingQuitList,
    SpamList,
    Roles,
    Titles,
    Color,
    Category,
    Banners,
    Templates,
    SearchStock,
    StockManagement,
    Languages,
    CustomerComments,
    References,
    Cases,
}