import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,

} from "../../../../../redux/actions/customerCommentActions";
import {
    Button,
    Modal
} from "react-bootstrap";
import {
    TextField,
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
} from "@material-ui/core";
import FileUpload from "../../../../helpers/fileupload/FileUpload";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function CustomerCommentsPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "name",
            text: "Ad",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.customerComment.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "surname",
            text: "Soyad",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.customerComment.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "title",
            text: "Görevi",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.customerComment.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "company",
            text: "Şirket",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.customerComment.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.customerComment.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.customerComment.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.customerComment.process === "create" ? "fa fa-plus" : props.customerComment.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.customerComment.process === "create" ? "Yeni Müşteri" : props.customerComment.process === "update" ? "Müşteri Yorumu Düzenle" : "Müşteri Yorumu Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.customerComment.process === "create" || props.customerComment.process === "update" ?
                    <div>
                        <TextField
                            label="Adı"

                            style={{ margin: 8 }}
                            placeholder="Adı"
                            autoComplete="off"
                            error={props.customerComment.name_error}
                            value={props.customerComment.name}
                            onChange={(event) => props.setValue("name", event.target.value)}
                            helperText={props.customerComment.name_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Soyadı"
                            style={{ margin: 8 }}
                            placeholder="Soyadı"
                            autoComplete="off"
                            error={props.customerComment.surname_error}
                            value={props.customerComment.surname}
                            onChange={(event) => props.setValue("surname", event.target.value)}
                            helperText={props.customerComment.surname_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Görevi"
                            style={{ margin: 8 }}
                            placeholder="Görevi"
                            autoComplete="off"
                            error={props.customerComment.title_error}
                            value={props.customerComment.title}
                            onChange={(event) => props.setValue("title", event.target.value)}
                            helperText={props.customerComment.title_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Şirket"
                            style={{ margin: 8 }}
                            placeholder="Şirket"
                            autoComplete="off"
                            error={props.customerComment.company_error}
                            value={props.customerComment.company}
                            onChange={(event) => props.setValue("company", event.target.value)}
                            helperText={props.customerComment.company_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Puan"
                            style={{ margin: 8 }}
                            placeholder="Puan"
                            autoComplete="off"
                            error={props.customerComment.point_error}
                            value={props.customerComment.point}
                            onChange={(event) => props.setValue("point", event.target.value)}
                            helperText={props.customerComment.point_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputMode="numeric"
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Yorum"
                            style={{ margin: 8 }}
                            placeholder="Yorum"
                            autoComplete="off"
                            error={props.customerComment.commentText_error}
                            value={props.customerComment.commentText}
                            onChange={(event) => props.setValue("commentText", event.target.value)}
                            helperText={props.customerComment.commentText_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <div style={{ marginTop: 8, marginLeft: 7, width: "100%" }}>
                            <FileUpload
                                {...props}
                                multiple={false}
                                dropzoneText={"Resim Seçiniz 120 x 120"}
                                stateKey={"image"}
                                uploadedImages={props.customerComment.image}
                            />
                        </div>
                        {
                            props.customerComment.process === "update" && props.customerComment.detail !== null ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>MÜŞTERİ YORUMU DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.customerComment.detail.createdBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.customerComment.detail.createdBy.name[0]).toUpperCase() + String(props.customerComment.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.customerComment.detail.createdBy.username}
                                                            /><br />
                                                            <small>{moment(props.customerComment.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.customerComment.detail.createdAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }

                                            {
                                                props.customerComment.detail.updatedBy !== undefined && props.customerComment.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.customerComment.detail.updatedBy.name[0]).toUpperCase() + String(props.customerComment.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.customerComment.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.customerComment.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.customerComment.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }
                    </div> :
                    props.table.row !== null && props.table.row.name ?
                        <>
                            <b>{'"'}{props.table.row.name}{' '}{props.table.row.surname}{'"'}</b> isimli müşterinin yorumunu silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.customerComment.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.customerComment.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.customerComment.isFetchingActions} onClick={() => {
                    if (props.customerComment.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "name",
                                    value: props.customerComment.name,
                                    required: true
                                },
                                {
                                    key: "surname",
                                    value: props.customerComment.surname,
                                    required: true
                                },
                                {
                                    key: "title",
                                    value: props.customerComment.title,
                                    required: true
                                },
                                {
                                    key: "company",
                                    value: props.customerComment.company,
                                    required: true
                                },
                                {
                                    key: "point",
                                    value: props.customerComment.point,
                                    required: true
                                },
                                {
                                    key: "commentText",
                                    value: props.customerComment.commentText,
                                    required: true
                                },
                                {
                                    key: "image",
                                    value: props.customerComment.image,
                                    required: false
                                }
                            ])
                    } else if (props.customerComment.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "name",
                                    value: props.customerComment.name,
                                    required: true
                                },
                                {
                                    key: "surname",
                                    value: props.customerComment.surname,
                                    required: true
                                },
                                {
                                    key: "title",
                                    value: props.customerComment.title,
                                    required: true
                                },
                                {
                                    key: "company",
                                    value: props.customerComment.company,
                                    required: true
                                },
                                {
                                    key: "point",
                                    value: props.customerComment.point,
                                    required: true
                                },
                                {
                                    key: "commentText",
                                    value: props.customerComment.commentText,
                                    required: true
                                },
                                {
                                    key: "image",
                                    value: props.customerComment.image,
                                    required: false
                                }

                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.customerComment.isFetchingActions ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.customerComment.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Müşteri Yorumları"
            buttonTitle="Yeni Müşteri Yorumu"
            cardIcon="fa fa-fill-drip"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.customerComment.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        customerComment: state.customerComment,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        update,
        deleteEntity,
        tableActions,

    })(CustomerCommentsPage);
