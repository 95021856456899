import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    name: "",
    name_helpertext: "",
    image: [],
    image_helpertext: "",
    image_error: false,
    name_error: false
}

export default function (state = initialState, action) {
    console.log(action)
    switch (action.type) {
        case "GET_ALL_REFERENCES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_REFERENCES":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_REFERENCES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_REFERENCES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_REFERENCES":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data,
                name: "",
                image: []
            }
        case "ERROR_INSERT_REFERENCES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_REFERENCE_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                name: action.payload.show ? state.name : "",
                image: action.payload.process === "create" ? [] : state.image,
                name_helpertext: "",
                image_helpertext: "",
                image_error: false,
                name_error: false
            }
        case "SET_VALUE_REFERENCE":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_REFERENCE":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_REFERENCE":
            return {
                ...state,
                isFetchingDetail: true,
                isFetchingActions: false,
                error: false
            }
        case "FETCH_DETAIL_REFERENCE":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                name: action.payload.data.name,
                image: action.payload.data.image !== null ? [globalURL + "/images/" + action.payload.data.image.name] : []
            }
        case "ERROR_DETAIL_REFERENCE":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_REFERENCE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_REFERENCE":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                name: "",
                image: [],
                name_helpertext: "",
                image_helpertext: "",
                image_error: false,
                name_error: false,
            }
        case "ERROR_UPDATE_REFERENCE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_REFERENCE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_REFERENCE":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_REFERENCE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }

        default:
            return state
    }

}
